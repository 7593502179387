<template>
  <div
    v-if="open"
    class="backdrop"
    @click="$emit('close')"
  ></div>
  <transition name="modal">
    <dialog
      open
      v-if="open"
      class="dialog"
      :class="dialogClass"
      ref="dialogEl"
      tabindex="-1"
      :style="dialogStyle"
    >
      <slot></slot>
    </dialog>
  </transition>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, onBeforeUnmount, onMounted, ref, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'
import focusTrap from 'focus-trap-js'

import { themeColors } from '@/common/shared.js'

export default {
  props: {
    dialogClass: {
      type: String,
      required: false,
    },
    open: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const store = useStore()

    const dialogStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
        border: `2px solid ${themeColors[store.getters.currentThemeName].toolBorderColor}`,
        color: themeColors[store.getters.currentThemeName].modalTextColor,
      }
    })

    const keydownListener = (e) => {
      if (isOpen.value && dialogEl.value) {
        focusTrap(e, dialogEl.value)
      }
      if (e.key === 'Escape' && props.open) {
        emit('close')
      }
    }

    const dialogEl = ref(null)
    const isOpen = computed(() => props.open)
    watch(isOpen, (newValue) => {
      if (!store.getters.jwtIsValid(new Date())) {
        window.location.href = `/login?redirect_url=${encodeURIComponent(location)}`
        return
      }
      setTimeout(() => {
        if (!newValue || !dialogEl.value) { return }
        dialogEl.value.focus()
      }, 50)
    })

    watchEffect(() => {
      if (isOpen.value) {
        document.body.style.overflowY = 'hidden'
        return
      }
      document.body.style.overflowY = 'auto'
    })

    onMounted(() => {
      document.addEventListener('keydown', keydownListener)
    })

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', keydownListener)
    })

    return {
      dialogEl,
      dialogStyle,
    }
  }
}
</script>


<style lang="scss" scoped>
@mixin responsive-modal($dialog-width) {
  .dialog {
    width: $dialog-width;
    left: calc(50% - 0.5 * #{$dialog-width});
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog {
  position: fixed;
  top: 6rem;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding: 0;
  background-color: white;
  z-index: 100;
  border: none;
  max-height: 85vh;
  overflow-y: auto;
  min-height: 300px; // Hack, since FindPatientModal couldn't pass style to child.
}

dialog:focus {
  outline: 0;
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.delete-session-modal {
  width: 60rem;
  left: calc(50% - 0.5 * 60rem);
}

::-webkit-scrollbar-thumb {
  border-top-right-radius: 48px;
  border-bottom-right-radius: 48px;
}

::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

@media screen and (max-width: 768px) {
  @include responsive-modal($dialog-width: 100vw);

  dialog {
    top: 0;
    left: 0;
    box-shadow: none;
    height: 100vh;
    max-height: 100vh;
  }
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
  @include responsive-modal($dialog-width: 80vw);
}

@media screen and (min-width: 1001px) {
  @include responsive-modal($dialog-width: 60vw);
}
</style>